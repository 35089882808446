import Mock from "../mock";

const database = {
  information: {
    name: "Stephanie Goldman",
    aboutContent:
      "More about me and some information about what Im working on. ",
    age: 29,
    phone: "",
    nationality: "Alicia Keys and Dua Lipa",
    language: "Javascript, Swift, Python, C, C++, Java",
    email: "stephaniegoldman4@gmail.com",
    address: "Chicago, Illinois",
    freelanceStatus: "",
    socialLinks: {
      // facebook: '',
      // twitter: '',
      // pinterest: '',
      // behance: '',
      linkedin: "https://www.linkedin.com/in/stephanie-goldman-a80a9246/",
      // dribbble: '',
      github: "https://github.com/stephaniegoldman12",
    },
    brandImage: "/images/prof_pic.jpg",
    aboutImage: "/images/swing_pic.jpg",
    aboutImageLg: "/images/swing_pic.jpg",
    cvfile: "/files/resume.pdf",
  },
  services: [
    {
      title: "Machine learning and math",
      icon: "code",
      details:
        "Focus more on the math underlying machine learning. Explore the worlds of unsupervised and reinforcement learning in greater depths. Learn PyTorch.",
    },
    {
      title: "Full-stack IoT project",
      icon: "code",
      details:
        "Learn more about the Raspberry Pi, and connect the data it collects to an AWS backend.",
    },
    {
      title: "Functional programming",
      icon: "code",
      details:
        "Build something with Clojure to work on fundamentals of functional programming.",
    },
  ],
  reviews: [
    {
      id: 1,
      content:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam, aliquid maxime tempora.",
      author: {
        name: "Burdette Turner",
        designation: "Web Developer, Abc Company",
      },
    },
    {
      id: 2,
      content:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam.",
      author: {
        name: "Susan Yost",
        designation: "Client",
      },
    },
    {
      id: 3,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
      author: {
        name: "Irving Feeney",
        designation: "Fiverr Client",
      },
    },
  ],
  skills: [
    {
      title: "HTML5",
      value: 95,
    },
    {
      title: "CSS3",
      value: 90,
    },
    {
      title: "Javascript",
      value: 70,
    },
    {
      title: "jQuery",
      value: 85,
    },
    {
      title: "ReactJS",
      value: 80,
    },
    {
      title: "Photoshop",
      value: 65,
    },
  ],
  portfolios: [
    {
      id: 1,
      title: "Bops Music",
      subtitle: "An app to effortlessly share music with friends.",
      imageUrl: "/images/image 2.png",
      largeImageUrl: ["/images/image 2.png"],
      url: "https://apps.apple.com/us/app/bops-music-with-friends/id1498351981",
    },
    {
      id: 2,
      title: "Map2Next",
      subtitle: "A place to get and share maps to encourage exploration.",
      imageUrl: "/images/map.png",
      largeImageUrl: ["/images/map.png", "/images/map.png"],
      url: "https://map2next.com",
    },
    {
      id: 3,
      title: "Instant Valuation",
      subtitle:
        "A python project which creates LBO and DCF analyses given a ticker.",
      imageUrl: "/images/analysis 1.png",
      largeImageUrl: ["/images/analysis 1.png"],
      url: "",
    },
    {
      id: 4,
      title: "NPR Tiny Desk Challenge",
      subtitle:
        "An automated tool that sends me a different Tiny Desk concert to watch daily.",
      imageUrl: "/images/npr.jpg",
      largeImageUrl: ["/images/npr.jpg"],
      url: "",
    },
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "November 2022 - Present",
        position: "CEO",
        company: "Stealth Startup",
        details:
          "I'm back to my roots on this one. Building software to make financial analysts more productive.",
      },
      {
        id: 2,
        year: "July 2021 - November 2022",
        position: "CTO",
        company: "Usabl",
        details: "We helped companies get feedback from developers.",
      },
      {
        id: 3,
        year: "June 2019 - Present",
        position: "Engineer",
        company: "Personal projects and learning",
        details:
          "Realized the massive potential of the tools, frameworks, and other technologies across engineering and spent every day exploring the landscape and my interests. See technical skills below for more details.",
      },
      {
        id: 4,
        year: "April 2018 - June 2019",
        position: "Founder & CEO",
        company: "Map2Next",
        details:
          "I think there's a lot more to be done with maps to help people navigate effectively. Learned a ton about the hustle of building businesses, product development, leading, and technology, but ultimately put Map2Next on hold to learn how to code myself.",
      },
      {
        id: 5,
        year: "August 2016 - March 2018",
        position: "Private Equity Associate",
        company: "GI Partners",
        details:
          "Worked on the IT Infrastructure team focused on investments in data centers and security companies. Worked on the $1.67bn acquisition of ViaWest and subsequent merger with Peak 10. Also worked in San Diego for a little over six months at a portfolio company on the FP&A team.",
      },
      {
        id: 6,
        year: "June 2014 – July 2016",
        position: "Investment Banking",
        company: "Barclays",
        details:
          "Advisor to Johnson Controls on $36 billion merger with Tyco International. Sell-side advisor to Goldman Sachs Capital Partners and P2 Capital Partners on $1.6 billion sale of Interline Brands to The Home Depot. ",
      },
    ],
    educationExperience: [
      {
        id: 1,
        year: "August 2010 - December 2013",
        graduation:
          "Bachelor of Science, Double Major in Finance and Economic Consulting",
        university: "Indiana University",
        details:
          "Received a merit scholarship which covered most of tuition and was awarded to one student annually. Aided three finite mathematics classes as a Teacher’s Assistant and worked as a Public Policy intern for a Bloomington Mayoral candidate.",
      },
    ],
  },
  blogs: [
    {
      id: 11,
      title: "George R. R. Martin",
      featuredImage: "/images/George-R.-R.-Martin-The-Simpsons-7088415.jpg",
      filesource: "../../blog/george_rr_martin.md",
      createDay: "26",
      createMonth: "Mayr",
      createYear: "2023",
    },
    {
      id: 10,
      title: "How to operate",
      featuredImage: "/images/customer-service.jpeg",
      filesource: "../../blog/how-to-operate.md",
      createDay: "9",
      createMonth: "December",
      createYear: "2022",
    },
    {
      id: 9,
      title: "Building Usabl",
      featuredImage: "/images/usabl-terminal.png",
      filesource: "../../blog/usabl-webapp.md",
      createDay: "1",
      createMonth: "June",
      createYear: "2022",
    },
    {
      id: 8,
      title: "Share work, not ideas",
      featuredImage: "/images/opinion.jpeg",
      filesource: "../../blog/sharework.md",
      createDay: "1",
      createMonth: "February",
      createYear: "2022",
    },
    {
      id: 7,
      title: "Wow programming moments",
      featuredImage: "/images/linear-system.png",
      filesource: "../../blog/delighted.md",
      createDay: "1",
      createMonth: "August",
      createYear: "2022",
    },
    {
      id: 6,
      title: "NPR Tiny Desk Challenge with Python",
      featuredImage: "/images/npr.jpg",
      filesource: "../../blog/automated-npr-challenge.md",
      createDay: "11",
      createMonth: "March",
      createYear: "2021",
    },

    {
      id: 5,
      title: "Instant Valuation example: BBY",
      featuredImage: "/images/analysis 1.png",
      filesource: "../../blog/instant-valuation.md",
      createDay: "1",
      createMonth: "February",
      createYear: "2021",
    },
    {
      id: 4,
      title: "Creating PPT Slides from Code",
      featuredImage: "/images/investor-update.png",
      filesource: "../../blog/ppt-from-code.md",
      createDay: "17",
      createMonth: "January",
      createYear: "2021",
    },
    {
      id: 3,
      title: "Fun with Excel Custom Functions",
      featuredImage: "/images/spherevolumenew.gif",
      filesource: "../../blog/excel-functions.md",
      createDay: "25",
      createMonth: "December",
      createYear: "2020",
    },
    {
      id: 2,
      title: "Building a website with React",
      featuredImage: "/images/codekickstart.png",
      filesource: "../../blog/codekickstart-website.md",
      createDay: "12",
      createMonth: "November",
      createYear: "2020",
    },
    {
      id: 1,
      title: "Bops Music",
      featuredImage: "/images/bops.png",
      filesource: "../../blog/bopsmusic.md",
      createDay: "15",
      createMonth: "September",
      createYear: "2020",
    },
    // {
    //   id: 6,
    //   title: 'Web Scraping',
    //   featuredImage: '/images/bopsmusic.png',
    //   filesource: '../../blog/bopsmusic.md',
    //   createDay: '1',
    //   createMonth: 'March',
    //   createYear: '2021',
    // },
    // {
    //   id: 4,
    //   title: 'PPT + Code = Magic.',
    //   featuredImage: '/images/mitchells.jpg',
    //   filesource: '../../blog/dessert-in-sanfrancisco.md',
    //   createDay: '1',
    //   createMonth: 'February',
    //   createYear: '2021',
    // },
  ],
  contactInfo: {
    phoneNumbers: ["+012-3456-7891", "+012-3456-7892"],
    emailAddress: ["admin.sitename@example.com", "info.sitename@example.com"],
    address: "121 King Street, Melbourne, Victoria 3000, Australia",
  },
};

Mock.onGet("/api/information").reply((config) => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply((config) => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply((config) => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply((config) => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply((config) => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply((config) => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply((config) => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply((config) => {
  const response = database.contactInfo;
  return [200, response];
});
