import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Particles from 'react-particles-js';
import Layout from '../components/Layout';
import Socialicons from '../components/Socialicons';
import { Fragment } from 'react'; // react version > 16.0
import db from '../config.js';

function InvestorSubscribe({ lightMode }) {
  const [information, setInformation] = useState('');
  const [email, setEmail] = useState('');
  const [showResults, setShowResults] = React.useState(false);

  const paramConfig = {
    particles: {
      number: {
        value: 160,
        density: {
          enable: false,
        },
      },
      color: {
        value: '#ffffff',
      },
      opacity: {
        value: 0.1,
      },
      size: {
        value: 5,
        random: true,
        anim: {
          speed: 4,
          size_min: 0.3,
        },
      },
      line_linked: {
        enable: false,
      },
      move: {
        random: true,
        speed: 1,
        direction: 'top',
        out_mode: 'out',
      },
    },
  };

  const alertEmail = () => {
    // alert(email);
    //save to db here
    db.collection('investor-update-subscribers')
      .add({
        email: email,
      })
      .then((docRef) => {
        console.log('Document written with ID: ', docRef.id);
        setShowResults(true);
        setEmail('');
      })
      .catch((error) => {
        console.error('Error adding document: ', error);
      });
  };

  const handleEmailInput = (e) => {
    setEmail(e.target.value);
  };

  const paramConfigLight = {
    particles: {
      number: {
        value: 160,
        density: {
          enable: false,
        },
      },
      color: {
        value: '#000000',
      },
      opacity: {
        value: 0.1,
      },
      size: {
        value: 5,
        random: true,
        anim: {
          speed: 4,
          size_min: 0.3,
        },
      },
      line_linked: {
        enable: false,
      },
      move: {
        random: true,
        speed: 1,
        direction: 'top',
        out_mode: 'out',
      },
    },
  };
  useEffect(() => {
    axios.get('/api/information').then((response) => {
      setInformation(response.data);
    });
  }, []);
  return (
    <Layout>
      <div className="mi-home-area mi-padding-section">
        <Particles
          className="mi-home-particle"
          params={lightMode ? paramConfigLight : paramConfig}
        />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-12">
              <div className="mi-home-content">
                <h1>
                  Subscribe for free to{' '}
                  <span className="color-theme">Investor Update</span>
                </h1>
                <h3 style={{ fontSize: '18px', lineHeight: '24px' }}>
                  For the first 200 people, subscribe now and receive the update
                  free for life. We'll email you when the website is available.
                </h3>
                <input
                  type="text"
                  id="email"
                  name="email"
                  onChange={handleEmailInput}
                  value={email}
                  placeholder="Email address"
                  style={{ width: '50%', fontSize: '16px' }}
                ></input>
                <button
                  onClick={alertEmail}
                  style={{
                    backgroundColor: 'white',
                    color: 'blue',
                    // marginTop: '10px',
                  }}
                >
                  Keep me posted
                </button>
                {showResults ? <Results /> : null}
                <img
                  style={{ width: '100%', marginTop: '20px' }}
                  src="/images/investor-update.png"
                ></img>

                <h3
                  style={{
                    fontSize: '18px',
                    marginTop: '20px',
                    lineHeight: '24px',
                  }}
                >
                  How the website will work:
                </h3>
                <h3 style={{ fontSize: '18px', lineHeight: '24px' }}>
                  1. Enter a watchlist
                </h3>
                <h3 style={{ fontSize: '18px', lineHeight: '24px' }}>
                  2. Receive an email summary and attached presentation with
                  information on both your watchlist and the market daily or
                  weekly
                </h3>
                <h3 style={{ fontSize: '18px', lineHeight: '24px' }}>
                  3. The above image is an example of the Table Of Contents for
                  the summary. It's subject to change as we build it. The goal
                  is to create an investment-grade and personalized resource for
                  retail investors
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

const Results = () => (
  <div id="results" className="search-results" style={{ color: 'green' }}>
    Thank you! We'll reach out when it's ready.
  </div>
);

export { InvestorSubscribe, Results };
