import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Particles from 'react-particles-js';
import Layout from '../components/Layout';
import Socialicons from '../components/Socialicons';

function BopsMusic({ lightMode }) {
  const [information, setInformation] = useState('');
  const paramConfig = {
    particles: {
      number: {
        value: 160,
        density: {
          enable: false,
        },
      },
      color: {
        value: '#ffffff',
      },
      opacity: {
        value: 0.1,
      },
      size: {
        value: 5,
        random: true,
        anim: {
          speed: 4,
          size_min: 0.3,
        },
      },
      line_linked: {
        enable: false,
      },
      move: {
        random: true,
        speed: 1,
        direction: 'top',
        out_mode: 'out',
      },
    },
  };

  const paramConfigLight = {
    particles: {
      number: {
        value: 160,
        density: {
          enable: false,
        },
      },
      color: {
        value: '#000000',
      },
      opacity: {
        value: 0.1,
      },
      size: {
        value: 5,
        random: true,
        anim: {
          speed: 4,
          size_min: 0.3,
        },
      },
      line_linked: {
        enable: false,
      },
      move: {
        random: true,
        speed: 1,
        direction: 'top',
        out_mode: 'out',
      },
    },
  };
  useEffect(() => {
    axios.get('/api/information').then((response) => {
      setInformation(response.data);
    });
  }, []);
  return (
    <Layout>
      <div className="mi-home-area mi-padding-section">
        <Particles
          className="mi-home-particle"
          params={lightMode ? paramConfigLight : paramConfig}
        />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-12">
              <div className="mi-home-content">
                <h1>
                  An introduction to{' '}
                  <span className="color-theme">Bops Music</span>
                </h1>
                <p>
                  Bops is a native iOS application written in Swift. Access a
                  profile of your music, and share/follow friends to play what
                  they're listening to.
                </p>
                <a
                  href="https://apps.apple.com/us/app/bops-music-with-friends/id1498351981"
                  className="mi-button"
                >
                  Bops on the App Store
                </a>
                <br></br>
                <img
                  style={{ marginTop: '20px' }}
                  src="/images/bops_screenshots.png"
                  alt="Bops Music: App Store Screenshots"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default BopsMusic;
