import axios from "axios";
import React, { useEffect, useState } from "react";
import Particles from "react-particles-js";
import Layout from "../components/Layout";
import Socialicons from "../components/Socialicons";

function Home({ lightMode }) {
  const [information, setInformation] = useState("");
  const paramConfig = {
    particles: {
      number: {
        value: 160,
        density: {
          enable: false,
        },
      },
      color: {
        value: "#ffffff",
      },
      opacity: {
        value: 0.1,
      },
      size: {
        value: 5,
        random: true,
        anim: {
          speed: 4,
          size_min: 0.3,
        },
      },
      line_linked: {
        enable: false,
      },
      move: {
        random: true,
        speed: 1,
        direction: "top",
        out_mode: "out",
      },
    },
  };

  const paramConfigLight = {
    particles: {
      number: {
        value: 160,
        density: {
          enable: false,
        },
      },
      color: {
        value: "#000000",
      },
      opacity: {
        value: 0.1,
      },
      size: {
        value: 5,
        random: true,
        anim: {
          speed: 4,
          size_min: 0.3,
        },
      },
      line_linked: {
        enable: false,
      },
      move: {
        random: true,
        speed: 1,
        direction: "top",
        out_mode: "out",
      },
    },
  };
  useEffect(() => {
    axios.get("/api/information").then((response) => {
      setInformation(response.data);
    });
  }, []);
  return (
    <Layout>
      <div className="mi-home-area mi-padding-section">
        <Particles
          className="mi-home-particle"
          params={lightMode ? paramConfigLight : paramConfig}
        />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-12">
              <div className="mi-home-content">
                <h1>
                  {" "}
                  <span className="color-theme">Stephanie 👩🏻‍💻 Goldman</span>
                </h1>
                <h5>Enjoy the challenge and build with ❤️ </h5>
                <p>
                  I created this website to share my background, opinions, and
                  post some experimental projects. I am the CEO of a startup in
                  stealth right now.
                  <br />
                  <br />
                  I'm based in Chicago. Prior to focusing on engineering, I
                  worked in Investment Banking and Private Equity. I enjoy: (1)
                  working on problems at the intersection of programming and
                  finance, (2) making things that people can use daily, and (3)
                  constantly learning new technologies.
                </p>
                <br />
                {/* <h5>print("Bring it on")</h5> */}
                <Socialicons bordered />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Home;
