import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

var firebaseConfig = {
  apiKey: 'AIzaSyCsDMIQ4qU2-1L0JPs436HhODJot6Z3rxE',
  authDomain: 'personal-website-hosting-f18c1.firebaseapp.com',
  projectId: 'personal-website-hosting-f18c1',
  storageBucket: 'personal-website-hosting-f18c1.appspot.com',
  messagingSenderId: '883562322006',
  appId: '1:883562322006:web:dbe55a057887f79db703f7',
  measurementId: 'G-65TNTQG1YL',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

var db = firebase.firestore();

console.log('initialized firestore');

export default db;
