import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Sectiontitle from "../components/Sectiontitle";

function TodayILearned() {
  return (
    <Layout>
      <div className="mi-about-area mi-section mi-padding-top">
        <div className="container">
          <Sectiontitle title="Today I Learned" />
        </div>
      </div>
    </Layout>
  );
}

export default TodayILearned;
