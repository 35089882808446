import axios from "axios";
import React, { useEffect, useState } from "react";
import Particles from "react-particles-js";
import Layout from "../components/Layout";
import Socialicons from "../components/Socialicons";
import db from "../config.js";
import { Results } from "./InvestorSubscribe";

function NPRSubscribe({ lightMode }) {
  const [information, setInformation] = useState("");
  const [email, setEmail] = useState("");
  const [showResults, setShowResults] = React.useState(false);
  const paramConfig = {
    particles: {
      number: {
        value: 160,
        density: {
          enable: false,
        },
      },
      color: {
        value: "#ffffff",
      },
      opacity: {
        value: 0.1,
      },
      size: {
        value: 5,
        random: true,
        anim: {
          speed: 4,
          size_min: 0.3,
        },
      },
      line_linked: {
        enable: false,
      },
      move: {
        random: true,
        speed: 1,
        direction: "top",
        out_mode: "out",
      },
    },
  };

  const paramConfigLight = {
    particles: {
      number: {
        value: 160,
        density: {
          enable: false,
        },
      },
      color: {
        value: "#000000",
      },
      opacity: {
        value: 0.1,
      },
      size: {
        value: 5,
        random: true,
        anim: {
          speed: 4,
          size_min: 0.3,
        },
      },
      line_linked: {
        enable: false,
      },
      move: {
        random: true,
        speed: 1,
        direction: "top",
        out_mode: "out",
      },
    },
  };
  useEffect(() => {
    axios.get("/api/information").then((response) => {
      setInformation(response.data);
    });
  }, []);

  const alertEmail = () => {
    // alert(email);
    //save to db here
    db.collection("tiny-desk-subscribers")
      .add({
        email: email,
      })
      .then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
        setShowResults(true);
        setEmail("");
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  };

  const handleEmailInput = (e) => {
    setEmail(e.target.value);
  };

  return (
    <Layout>
      <div className="mi-home-area mi-padding-section">
        <Particles
          className="mi-home-particle"
          params={lightMode ? paramConfigLight : paramConfig}
        />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-12">
              <div className="mi-home-content">
                <h1>
                  Want to try the{" "}
                  <span className="color-theme"> NPR Tiny Desk Challenge?</span>
                </h1>
                <img src="/images/npr.jpg"></img>
                <p>
                  Completely free. Receive a Tiny Desk each day for a month.
                  Expose yourself to new music. Sign up and start the challenge
                  at 4pm PST.
                </p>
                <input
                  type="text"
                  id="email"
                  name="email"
                  onChange={handleEmailInput}
                  value={email}
                  placeholder="Email address"
                  style={{ width: "50%", fontSize: "16px" }}
                ></input>
                <button
                  onClick={alertEmail}
                  style={{
                    backgroundColor: "white",
                    color: "blue",
                    // marginTop: '10px',
                  }}
                >
                  Subscribe
                </button>
                {showResults ? <Results /> : null}
                <br></br>
                <p style={{ marginTop: "20px" }}>
                  I'm sharing a blog post on how this was built as well. It's
                  not ready yet, but is available here:{" "}
                  <a href="http://stephgoldman.com/blog/blog-details/1/automated-npr-challenge">
                    Blog Post
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default NPRSubscribe;
