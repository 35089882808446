import React, { useState, useEffect } from "react";
import axios from "axios";
import TrackVisibility from "react-on-screen";
import Sectiontitle from "../components/Sectiontitle";
import Smalltitle from "../components/Smalltitle";
import Layout from "../components/Layout";
import Progress from "../components/Progress";
import Resume from "../components/Resume";

function Resumes() {
  const [skills, setSkills] = useState([]);
  const [workingExperience, setWorkingExperience] = useState([]);
  const [educationExperience, setEducationExperience] = useState([]);

  useEffect(() => {
    axios.get("/api/skills").then((response) => {
      setSkills(response.data);
    });
    axios.get("/api/experience").then((response) => {
      setWorkingExperience(response.data.workingExperience);
      setEducationExperience(response.data.educationExperience);
    });
  }, []);

  return (
    <Layout>
      {/* <div className="mi-skills-area mi-section mi-padding-top"> */}
      {/* <div className="container">
          <Sectiontitle title="My Creations" />
          <div className="mi-skills">
            <div className="row mt-30-reverse">
              {skills.map((skill) => (
                <TrackVisibility
                  once
                  className="col-lg-6 mt-30"
                  key={skill.title}
                >
                  <Progress title={skill.title} percentage={skill.value} />
                </TrackVisibility>
              ))}
            </div>
          </div>
        </div> */}
      {/* </div> */}
      <div className="mi-resume-area mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <Sectiontitle title="Resume" />
          {/* <button style={{ marginBottom: "24px" }}>Download it</button>  */}
          <Smalltitle title="Experience" icon="briefcase" />
          <div className="mi-resume-wrapper">
            {workingExperience.map((workingExp) => (
              <Resume key={workingExp.id} resumeData={workingExp} />
            ))}
          </div>
          <div className="mt-30"></div>
          <Smalltitle title="Education" icon="book" />
          <div className="mi-resume-wrapper">
            {educationExperience.map((educatonExp) => (
              <Resume key={educatonExp.id} resumeData={educatonExp} />
            ))}
          </div>
          {/* Technical skills */}
          <br></br>
          <Smalltitle title="Technical Skills" icon="code" />
          <div className="mi-resume-wrapper">
            <div className="mi-resume mt-30">
              <div className="mi-resume-summary">
                <h6 className="mi-resume-year">Lifelong journey</h6>
              </div>
              <div className="mi-resume-details">
                <h5>Programming languages</h5>
                <h6 className="mi-resume-company">
                  Python, C, C++, Java, Swift, Javascript
                </h6>
                <h5>Data analysis and machine learning</h5>
                <h6 className="mi-resume-company">
                  {" "}
                  Machine learning (mostly supervised) and data visualization
                  with numpy, pandas, seaborn, and matplotlib
                </h6>
                <h5>App development</h5>
                <h6 className="mi-resume-company">
                  Swift with some experience in React Native. Have built two
                  native iOS applications from scratch
                </h6>
                <h5>Web development</h5>
                <h6 className="mi-resume-company">
                  React.js, Next.js, Javascript, CSS, HTML, Wordpress, Flask,
                  Django
                </h6>
                <h5>Databases and cloud</h5>
                <h6 className="mi-resume-company">
                  MySQL, SQL, Firebase, AWS and Google Cloud Services,
                  Serverless
                </h6>
                <h5>Other, design and product</h5>
                <h6 className="mi-resume-company">
                  Data structures, Algorithms, API’s, Linux, Bash Scripting,
                  Docker, Figma, Semantic UI, JIRA, Trello
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Resumes;
