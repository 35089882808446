import React, { useState } from "react";
import * as Icon from "react-feather";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.scss";
import About from "./pages/About";
import BopsMusic from "./pages/BopsMusic";
import BlogDetails from "./pages/BlogDetails";
import Blogs from "./pages/Blogs";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Notfound from "./pages/Notfound";
import Portfolios from "./pages/Portfolios";
import Resumes from "./pages/Resumes";
import NPRSubscribe from "./pages/NPRSubscribe";
import { InvestorSubscribe } from "./pages/InvestorSubscribe";
import { hotjar } from "react-hotjar";
import Perspectives from "./pages/Perspectives";
import TodayILearned from "./pages/TodayILearned";

hotjar.initialize("2998937", "6");

function App() {
  const [lightMode, setLightMode] = useState(false); // Made it true if you want to load your site light mode primary

  lightMode
    ? document.body.classList.add("light")
    : document.body.classList.remove("light");

  const handleMode = () => {
    if (!lightMode) {
      setLightMode(true);
      document.body.classList.add("light");
    } else {
      setLightMode(false);
      document.body.classList.remove("light");
    }
  };

  return (
    <BrowserRouter>
      <div className="light-mode">
        <span className="icon">
          <Icon.Sun />
        </span>
        <button
          className={
            lightMode ? "light-mode-switch active" : "light-mode-switch"
          }
          onClick={() => handleMode()}
        ></button>
      </div>
      <Switch>
        <Route path="/" exact>
          <Home lightMode={lightMode} />
        </Route>
        <Route path="/about" component={About} />
        <Route path="/bops-music" component={BopsMusic} />

        <Route path="/resume" component={Resumes} />
        <Route path="/tiny-desk-subscribe" component={NPRSubscribe} />
        <Route
          path="/investor-update-subscribe"
          component={InvestorSubscribe}
        />

        <Route path="/building" component={Portfolios} />
        <Route path="/perspectives" component={Perspectives} />
        <Route path="/today-i-learned" component={TodayILearned} />
        <Route path="/blog" exact component={Blogs} />
        <Route path="/blog/blog-details/:id/:title" component={BlogDetails} />
        {/* <Route path="/contact" component={Contact} /> */}
        <Route path="*" component={Notfound} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
